import { Button, Card, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import loader from "../../../assests/images/loader.svg";
import { changePassword, checkingCurrentPassword } from '../../../api/profile';
import { isFieldEmpty } from '../../../Utility/helper';

const PasswordChange = ({ userId }) => {
    const [passwordList, setPasswordList] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [passwordCondition, setPasswordCondition] = useState({
        initialPass: true,
        visibleCurrentPass: false,
        updatePassword: false,
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleCheckingCurrentPassword = async () => {
        setIsLoading(true);
        try {
            const response = await checkingCurrentPassword(userId, passwordList.currentPassword);

            if (response?.data?.matchedPassword) {
                setPasswordCondition((prevState) => ({
                    ...prevState,
                    initialPass: false,
                    visibleCurrentPass: false,
                    updatePassword: true,
                }));
            }

            if(response?.status === 400) {
                toast.error(response.response.data.message)
            }
        } catch (error) {
            toast.error(error?.message);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangePassword = async () => {
        try {
            const response = await changePassword(userId, passwordList.newPassword, passwordList.confirmPassword);

            if (response?.data?.changedPassword) {
                setPasswordCondition({
                    initialPass: true,
                    visibleCurrentPass: false,
                    updatePassword: false,
                });
                toast.success(response?.data?.message);
            }
            if(response?.status === 400) {
                toast.error(response.response.data.message)
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Card title="Update Password" className="profile-card__container">
            {passwordCondition.initialPass && !passwordCondition.updatePassword && !passwordCondition.visibleCurrentPass && (
                <Button
                    onClick={() => setPasswordCondition({ initialPass: false, visibleCurrentPass: true, updatePassword: false })}
                    block
                    style={{ backgroundColor: "#2952BF", color: "#fff", padding: "10px" }}
                >
                    Change password
                </Button>
            )}
            {!passwordCondition.initialPass && passwordCondition.visibleCurrentPass && !passwordCondition.updatePassword && (
               <Form onFinish={handleCheckingCurrentPassword}>
                <Form.Item style={{ margin: 0 }}>
                    <Input.Password
                        name="currentPassword"
                        placeholder="Current Password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        autoComplete="new-password"
                        style={{ marginBottom: "10px" }}
                        onChange={(e) => setPasswordList((prevState) => ({ ...prevState, currentPassword: e.target.value }))}
                    />
                </Form.Item>
                <Form.Item style={{ margin: 0 }}>
                    <Button
                        type="primary"
                        block
                        style={{ backgroundColor: "#2952BF", color: "#fff", padding: "10px" }}
                        disabled={isFieldEmpty(passwordList?.currentPassword)}
                        htmlType="submit" // Ensures it's treated as a submit button
                    >
                        {isLoading ? <img style={{ width: "25px" }} src={loader} alt="loader" /> : "Submit"}
                    </Button>
                </Form.Item>
              </Form>
            )}
            {!passwordCondition.initialPass && !passwordCondition.visibleCurrentPass && passwordCondition.updatePassword && (
                <>
                    <Input.Password
                        type="password"
                        name="newPassword"
                        placeholder="New Password"
                        autoComplete="new-pass"
                        style={{ marginBottom: "10px" }}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        onChange={(e) => setPasswordList((prevState) => ({ ...prevState, newPassword: e.target.value }))}
                    />
                    <Input.Password
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        autoComplete="con-pass"
                        style={{ marginBottom: "10px" }}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        onChange={(e) => setPasswordList((prevState) => ({ ...prevState, confirmPassword: e.target.value }))}
                    />
                    <Button
                        onClick={handleChangePassword}
                        block
                        style={{ backgroundColor: "#2952BF", color: "#fff", padding: "10px" }}
                        disabled={isFieldEmpty(passwordList?.newPassword) || isFieldEmpty(passwordList?.confirmPassword)}
                   >
                        Change Password
                    </Button>
                </>
            )}
        </Card>
    );
};

export default PasswordChange;