export const EditAssistantTypeFormData = [
    {
      name: "name",
      label: "Agent Type",
      rules: [
        {
          required: true,
          message: 'Please input agent type',
        },
      ],
    }
  ];