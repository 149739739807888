
import { GET_OR_DELETE_GOOGLE_DRIVE_AUTH_CREDENTIALS } from "../constants/Api_constants";
import { axiosSecureInstance } from "./axios";
import { getUserID } from "../Utility/service";
const userIdFromLocal = getUserID();
export const getGoogleAuthCredentials = async (userId,setIsConnected,setToken)=>{
    if(userIdFromLocal){
        const isGoogleAuthCredentialsExist = await axiosSecureInstance.get(GET_OR_DELETE_GOOGLE_DRIVE_AUTH_CREDENTIALS(userIdFromLocal));
        if(isGoogleAuthCredentialsExist.data.data){
            setToken(isGoogleAuthCredentialsExist.data.data.accessToken);
            setIsConnected(true);
        }else{
            setIsConnected(false);
        }
        return isGoogleAuthCredentialsExist?.data?.data || []; 
    }
    return [];

}