import React, { useContext, useRef, useEffect, useState } from "react";
import { Button, Select, Tooltip, message } from "antd";
import { PlusOutlined, SendOutlined } from "@ant-design/icons";
import { CgAttachment } from "react-icons/cg";
import { ThemeContext } from "../../../src/contexts/themeConfig";
import { MdDeleteOutline } from "react-icons/md";
import { axiosSecureInstance } from "../../api/axios";
import useAssistantsChatPage from "../../Hooks/useAssistantsChatPage";
import { getWorkBoardActionItems } from "../../api/workBoard";
import { FileContext } from "../../contexts/FileContext";
import { RiImageAddFill } from "react-icons/ri";
import { FaRegStopCircle } from "react-icons/fa";
import ChatBoxWrapper from "../ChatPage/ChatPageWraper";
const AssistantChatInputPrompt = ({ states, actions }) => {
  const { theme } = useContext(ThemeContext);
  const {
    selectedFiles,
    loading,
    inputPrompt,
    isUploadingFile,
    isGeneratingResponse,
    assistant_id,
    connectedApps,
    selectedFileAppWithFileId,
  } = states;
  const [selectedApp, setSelectedApp] = useState("");
  const [appFileList, setAppFileList] = useState([]);
  const { workBoardToken, setWorkBoardToken } = useContext(FileContext);
  const promptInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const {
    onSubmit,
    handleKeyDown,
    onInputPromptChange,
    handleFileChange,
    handleFileRemove,
    setSelectedFileAppWithFileId,
    stopGeneratingResponse,
    // showSelectors = true,
  } = actions;
  useEffect(() => {
    if (promptInputRef.current) {
      adjustTextareaHeight();
    }
  }, [inputPrompt]);
  useEffect(() => {
    if (promptInputRef.current) {
      adjustTextareaHeight();
    }
  }, [inputPrompt]);
  useEffect(() => {
    if (selectedApp === "WorkBoard") {
      if (
        workBoardToken !== "" &&
        workBoardToken !== null &&
        workBoardToken !== undefined
      ) {
        getWorkBoardActionItems().then((response) => {
          if (response !== "") {
            setAppFileList(response);
          } else {
            message.error("Please Sync Your workBoard ActionItems");
          }
        });
      } else {
        setAppFileList([]);
        message.error("Please Connect Your WorkBoard");
      }
    } else if (selectedApp === "GoogleDrive") {
      setAppFileList([]);
    }
  }, [selectedApp]);
  const adjustTextareaHeight = () => {
    const textarea = promptInputRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  const formatKey = (key) => {
    const formattedKey = key.charAt(0).toUpperCase() + key.slice(1);
    const formattedLabel = formattedKey.replace(/([A-Z])/g, " $1").trim();
    return {
      value: formattedKey,
      label: <span>{formattedLabel}</span>,
    };
  };
  const handleAppSelect = async (appName) => {
    if (appName === undefined) {
      setSelectedApp("");
      setAppFileList([]);
      setSelectedFileAppWithFileId({
        appName: "",
        fileIdOrUrl: "",
        fileName: "",
      });
    } else {
      setSelectedApp(appName);
      if (selectedApp === "GoogleDrive") {
        setSelectedFileAppWithFileId({
          appName: selectedApp,
          fileIdOrUrl: "",
          fileName: "",
        });
      }
    }
  };
  let appFilteredList = connectedApps
    ?.filter((app) => Object?.values(app)[0] === true)
    ?.map((app) => formatKey(Object?.keys(app)[0]));
  let fileNameLists = [
    ...(appFileList?.personal
      ? appFileList?.personal?.map((file) => ({
          label: file?.description,
          value: file?.url,
        }))
      : []),
    ...(appFileList?.inLoop
      ? appFileList?.inLoop?.map((file) => ({
          label: file?.description,
          value: file?.url,
        }))
      : []),
  ];
  const handleFileNameChange = (fileId) => {
    const selectedFile = fileNameLists?.find(
      (option) => option?.value === fileId
    );
    const fileName = selectedFile ? selectedFile?.label : "";
    setSelectedFileAppWithFileId({
      appName: selectedApp,
      fileIdOrUrl: fileId,
      fileName: fileName,
    });
    return;
  };
  return (
    <>
      <form
        className={`form-style-assistant ${theme === "dark" && "dark-mode"}`}
      >
        {
          isGeneratingResponse ?
            <div className="stop-generating-btn-container">
             <Button danger type="primary" onClick={stopGeneratingResponse} style={{ width: "160px", }} icon={<FaRegStopCircle />} size={30}>
                Stop Generating
              </Button>
            </div>
            :
            ""
        }
        <div
          className={`inputPromptTextarea-container-assistant ${
            theme === "dark" && "dark-mode"
          }`}
        >
          <div
            className={`select-container-whole ${
              theme === "dark" && "dark-mode"
            }`}
          >
            {/* <div className="select-container">
              <Tooltip title="Choose an App">
                <Select
                  style={{ width: "30%" }}
                  placeholder="Choose an App"
                  onChange={handleAppSelect}
                  options={appFilteredList}
                  defaultValue={[]}
                  allowClear
                  className="custom-select-bot"
                  placement="topLeft"
                />
              </Tooltip>
              <Tooltip title="Choose a File">
                <Select
                  style={{ width: "70%" }}
                  placeholder="Choose File"
                  showSearch
                  onChange={handleFileNameChange}
                  options={fileNameLists}
                  allowClear
                  notFoundContent={loading ? "Loading..." : null}
                  className="custom-select-file"
                  placement="topLeft"
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Tooltip>
            </div> */}
          </div>
          <textarea
            ref={promptInputRef}
            autoComplete="off"
            placeholder="Ask me anything..."
            name="inputPrompt"
            className={`inputPrompttTextarea-assistant assistantchat ${
              theme === "dark" && "dark-mode"
            }`}
            rows="1"
            value={inputPrompt}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              onInputPromptChange(e);
              adjustTextareaHeight();
            }}
            style={{
              height: "auto",
              maxHeight: "250px",
              overflowY: "auto",
              paddingTop: "16px",
            }}
          />
          <div className="actions-container">
            <Tooltip title="Attach">
              <button
                className="plus-button"
                aria-label="Attach"
                type="button"
                onClick={() => fileInputRef.current.click()}
              >
                <RiImageAddFill />
              </button>
            </Tooltip>
            <input
              type="file"
              accept="pdf/*"
              multiple
              style={{ display: "none" }}
              onChange={handleFileChange}
              ref={fileInputRef}
            />
            {/*      Commenting for now. will remove this later if not needed
                  <button
                    // disabled={loading || !inputPrompt.trim()}
                    // aria-label="Stop response"
                    className="stopIcon"
                    type="button"
                    onClick={stopGeneratingResponse}
                  >
                    <FaRegStopCircle />
                  </button>
          */}
            <button
              disabled={loading || !inputPrompt.trim()}
              aria-label="form submit"
              className="sendIcon"
              type="button"
              onClick={onSubmit}
            >
              <SendOutlined />
            </button>
          </div>
        </div>
        {selectedFiles.length > 0 && (
          <div className="assistantInputFilesContainer">
            {selectedFiles.map((file, index) => (
              <div key={index} className="assistantInputFile">
                <small className="text-truncate">{file.name}</small>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFileRemove(file);
                  }}
                  disabled={isUploadingFile}
                >
                  <MdDeleteOutline />
                </button>
              </div>
            ))}
          </div>
        )}
      </form>
    </>
  );
};
export default AssistantChatInputPrompt;












