import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Form, message, Space } from 'antd';
import axios from 'axios';
import { axiosSecureInstance } from '../../api/axios';

const AuthorizeEmailDomain = () => {
    const [form] = Form.useForm();
    const [emailDomains, setEmailDomains] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    const [editingDomain, setEditingDomain] = useState(null);

    const fetchEmailDomains = async () => {
        setLoading(true);
        try {
            const response = await axiosSecureInstance.get('api/email-domains');
            setEmailDomains(response.data);
        } catch (error) {
            message.error('Failed to fetch email domains');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (values) => {
        try {
            const response = await axiosSecureInstance.post('api/email-domains/authorize', values);
            message.success('Email domain authorized successfully');
            form.resetFields();
            fetchEmailDomains();
        } catch (error) {
            message.error('Failed to authorize email domain');
        }
    };

    const handleDelete = async (id) => {
        try {
            await axiosSecureInstance.delete(`api/email-domains/${id}`);
            message.success('Email domain deleted successfully');
            fetchEmailDomains();
        } catch (error) {
            message.error('Failed to delete email domain');
        }
    };

    const handleEdit = (record) => {
        setEditingKey(record._id);
        setEditingDomain({ ...record });
    };

    const handleUpdate = async () => {
        try {
            await axiosSecureInstance.patch(`api/email-domains/${editingKey}`, editingDomain);
            message.success('Email domain updated successfully');
            setEditingKey('');
            fetchEmailDomains();
        } catch (error) {
            message.error('Failed to update email domain');
        }
    };

    const cancelEdit = () => {
        setEditingKey('');
        setEditingDomain(null);
    };

    const onInputChange = (e, field) => {
        setEditingDomain({ ...editingDomain, [field]: e.target.value });
    };

    useEffect(() => {
        fetchEmailDomains();
    }, []);

    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            align: 'left',
            render: (_, record) =>
                editingKey === record._id ? (
                    <Input value={editingDomain.companyName} onChange={(e) => onInputChange(e, 'companyName')} />
                ) : (
                    record.companyName
                ),
        },
        {
            title: 'Email Domain',
            dataIndex: 'emailDomain',
            key: 'emailDomain',
            align: 'left',
            render: (_, record) =>
                editingKey === record._id ? (
                    <Input value={editingDomain.emailDomain} onChange={(e) => onInputChange(e, 'emailDomain')} />
                ) : (
                    record.emailDomain
                ),
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'left',
            render: (_, record) =>
                editingKey === record._id ? (
                    <Space>
                        <Button type="primary" onClick={handleUpdate}>Update</Button>
                        <Button onClick={cancelEdit}>Cancel</Button>
                    </Space>
                ) : (
                    <Space>
                        <Button type="link" onClick={() => handleEdit(record)}><i class="bi bi-pencil-square"></i></Button>
                        <Button type="link" danger onClick={() => handleDelete(record._id)}><i class="bi bi-trash"></i></Button>
                    </Space>
                ),
        },
    ];

    return (
        <div style={{ padding: '20px' }}>
            <h3 style={{ marginBottom: '25px' }}>Authorize Email Domain</h3>
            <Form
                form={form}
                layout="inline"
                onFinish={handleSubmit}
                style={{ marginBottom: '20px', }}
            >
                <Form.Item
                    name="companyName"
                    rules={[{ required: true, message: 'Please input company name!' }]}
                >
                    <Input placeholder="Company Name" />
                </Form.Item>
                <Form.Item
                    name="emailDomain"
                    rules={[
                        { required: true, message: 'Please input email domain!' },
                        {
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.reject('Please input email domain!');
                                }
                                if (!/^@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/.test(value)) {
                                    return Promise.reject('Please enter a valid email domain!');
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <Input placeholder="Email Domain" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" >
                        Submit
                    </Button>
                </Form.Item>
            </Form>

            <Table
                columns={columns}
                dataSource={emailDomains}
                rowKey="_id"
                loading={loading}
                pagination={{ pageSize: 5 }}
            />
        </div>
    );
};

export default AuthorizeEmailDomain;
