import { Typography } from 'antd'
import { useEffect } from 'react';
import { getSingleAssistant } from '../../api/assistantChatPageApi';
import { useState } from 'react';
import AssistantIcon from '../../component/common/AssistantIcon';
import { Avatar } from 'antd';

const { Title } = Typography;

const AssistantInfo = ({dataProps}) => {
    const {assistantAllInfo, assistant_id} = dataProps;
    const [assistantData, setAssistantData] = useState({});
    const [isExpanded, setIsExpanded] = useState(false);
    const maxWords = 50;
    
    const fetchAssistantData = async()=>{
        const response = await getSingleAssistant(assistant_id)
        setAssistantData(response?.assistant)
    }

    useEffect(()=>{
        fetchAssistantData()
    },[assistant_id])

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

      // Split the description into words
    const descriptionWords = assistantData?.description
    ? assistantData.description.split(" ")
    : [];

const truncatedDescription = descriptionWords.slice(0, maxWords).join(" ") + (descriptionWords.length > maxWords ? "..." : "");
   
    //style={{ height: "100vh", }}
    return (
        <div>
            <div className="assistant-info-container-on-chat-page">
                <div className="assistant-img">
                    <div>
                    {
                     assistantData?.image_url 
                     ? 
                     <Avatar size={56} src={assistantData.image_url} className='mb-2'/>
                     :
                     <AssistantIcon/>
                    }
                    </div>
                </div>
                <Title level={4}>{assistantData?.name}</Title>

                <div className='assistant-creator-container'>
                <p className='assistant-creator'>By {assistantData?.userId?.fname} {assistantData?.userId?.lname}</p>
                </div>
                <div className='assistant-description'>
                    <p>
                        {isExpanded
                            ? assistantData.description
                            : truncatedDescription
                        }
                        {descriptionWords.length > maxWords && (
                            <span
                                onClick={toggleExpand}
                                style={{ color: "rgb(120,120,120)", fontWeight:"600", cursor: "pointer", marginLeft: '5px' }}
                            >
                                {isExpanded ? "Show less" : "See more..."}
                            </span>
                        )}
                    </p>
                </div>               
            </div>
        </div>
    )
}

export default AssistantInfo

