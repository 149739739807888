import { Button, Form, Modal, Space, Switch, Table, Tag, Tooltip, message, notification } from "antd";
import { useEffect, useState } from "react";
import { TbPlugConnectedX } from "react-icons/tb"; 
import { useGoogleLogin } from '@react-oauth/google';
import { getUserID } from "../../Utility/service";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { getGoogleAuthCredentials } from "../../api/googleAuthApi";
import { axiosSecureInstance } from "../../api/axios";
import googleDriveIcon from '../../assests/images/google-drive-icon.png';
import workBoardIcon from '../../assests/images/workboard.png';
import webPageIcon from '../../assests/images/webpageicon.png';
import fireCrawl from '../../assests/images/fireCrawl.png';
import { FileContext } from "../../contexts/FileContext";
import { GET_OR_DELETE_GOOGLE_DRIVE_AUTH_CREDENTIALS, GOOGLE_AUTH_SLUG, GOOGLE_DRIVE_FILES_GETTING_SLUG, REACT_APP_WORKBOARD_AUTH_URL, REACT_APP_WORKBOARD_REDIRECT_URI, SYNC_GOOGLE_DRIVE_FILES, SYNC_WORKBOARD_ACTION_ITEM, WORKBOARD_AUTH_SLUG } from "../../constants/Api_constants";
import { useContext } from "react";
import { getWorkBoardAuthCredentials } from "../../api/workBoard";
import { InputModal } from "../common/InputModal";
import { AddWebCrawlKeyFormData } from "./webCrawlForms";
import { deleteFireCrawlKeyFromDB, storeFireCrawlKeyToDB } from "../../api/webCrawl";
const { Title } = Typography;
const userId = getUserID();


export const IntegrateApplications = () => {
  const [form] = Form.useForm();

  let workboardToken = localStorage.getItem('workboard_access_token') || '';
  const [applicationList, setApplicationList] = useState([
    { key: '1', name: 'Google Drive', icon: <img src={googleDriveIcon} alt="Google Drive Icon" style={{ width: 30, height: 30, marginRight: 8 }} /> },
    { key: '2', name: 'WorkBoard', icon: <img src={workBoardIcon} alt="work Board Icon" style={{ width: 30, height: 30, marginRight: 8 }} /> },
  ]);

  const {
    isConnected, 
    setIsConnected,
    token, 
    setToken,
    isWorkBoardConnected, 
    setIsWorkBoardConnected,
    workBoardToken, 
    setWorkBoardToken,
    isWebCrawlConnected,
    setIsWebCrawlConnected
  }=useContext(FileContext);

  const [showSyncFileAlertModal, setShowSyncFileAlertModal] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [isWebCrawlerModalOpen,setIsWebCrawlerModalOpen] = useState(false);

  useEffect(()=>{
    if(workboardToken){
      setWorkBoardToken(workboardToken);
    }

  },[workboardToken]);
  
  useEffect(() => {
    if (token) {
      fetch(GOOGLE_DRIVE_FILES_GETTING_SLUG, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .catch((error) => console.error('Error fetching files:', error));
    }
  }, [token]);

  useEffect(() => {
    getGoogleAuthCredentials(userId, setIsConnected, setToken);
    getWorkBoardAuthCredentials(userId,setIsWorkBoardConnected,setWorkBoardToken);
  }, []);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const body = { userId, code: codeResponse.code };
      const response = await axiosSecureInstance.post(GOOGLE_AUTH_SLUG, body);
      const accessToken = response?.data?.token;
      setToken(accessToken);
      setIsConnected(true);
    },
    flow: 'auth-code',
    scope: [
      'profile',
      'email',
      'https://www.googleapis.com/auth/drive.readonly',
      'https://www.googleapis.com/auth/drive.file',
      'https://www.googleapis.com/auth/drive.metadata.readonly',
      'https://www.googleapis.com/auth/drive',
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
    ].join(' '),
    prompt: 'consent',
  });

  const logout = async () => {
    await axiosSecureInstance.delete(GET_OR_DELETE_GOOGLE_DRIVE_AUTH_CREDENTIALS(userId));
    setIsConnected(false);
  };
  const syncWorkBoardActionItem = async () => {
    setIsLoading(true);
    try {
      const startTime = Date.now();
      const response = await axiosSecureInstance.get(SYNC_WORKBOARD_ACTION_ITEM(userId));
      const endTime = Date.now();
  
      const timeTaken = endTime - startTime;
  
      notification.open({
        message: 'Work Board Action Item Synced',
        description: 'All Personal and InLoop Action Items are synced successfully.',
        placement: 'bottomRight',
        style: {
          backgroundColor: '#6697cc',
          border: '1px solid #6697cc',
        },
      });
    } catch (error) {
      console.error("Error syncing WorkBoard:", error);
      notification.open({
        message: 'Sync Failed',
        description: 'Failed to sync WorkBoard Action Items. Please try again later.',
        placement: 'bottomRight',
        style: {
          backgroundColor: '#ff4d4f',
          border: '1px solid #ff4d4f',
        },
      });
    } finally {
      setIsLoading(false);
    }

  };

  const syncGoogleDriveFiles = async () => {
    const response = await axiosSecureInstance.get(SYNC_GOOGLE_DRIVE_FILES(userId));
    notification.open({
      message: 'Google Drive Files Synced',
      description: `Total ${response.data.allDownloadedFileList.length} Files Are Synced.`,
      placement: 'bottomRight',
      style: {
        backgroundColor: '#6697cc',
        border: '1px solid #6697cc',
      },
    });
  };

  const handleConnectWorkBoard = () => {
    const clientId = process.env.REACT_APP_WORKBOARD_CLIENT_ID;
    const redirectUri = REACT_APP_WORKBOARD_REDIRECT_URI;
    const authorizationUrl = `${REACT_APP_WORKBOARD_AUTH_URL}?client_id=${clientId}&redirect_uri=${redirectUri}`;
    window.location.href = authorizationUrl;
  };

  const handleRemoveWorkBoard = async () => {
    Modal.confirm({
      title: 'Confirm Disconnection',
      content: 'Are you sure you want to disconnect your WorkBoard account?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        localStorage.removeItem('workboard_access_token');
        const response = await axiosSecureInstance.delete(WORKBOARD_AUTH_SLUG(userId));
        setIsWorkBoardConnected(false);
        setWorkBoardToken('');
        message.success(response.data.message);
      },
    });
  };

  const handleWebCrawlConnect =()=>{
    setIsWebCrawlerModalOpen(true);

  };

  const handleWebCrawlDisConnect = async()=>{
    const responseOfFireCrawlKeyDelete = await deleteFireCrawlKeyFromDB();
    if(responseOfFireCrawlKeyDelete.status === 200){
      setIsWebCrawlConnected(false);
      message.success(responseOfFireCrawlKeyDelete.data.message);
    }else{
      message.error(responseOfFireCrawlKeyDelete.data.message);
    }
    return responseOfFireCrawlKeyDelete;
  };

  const applicationData = [
    { key: '1', name: 'Google Drive', icon: <img src={googleDriveIcon} alt="Google Drive Icon" style={{ width: 25, height: 25, marginRight: 9 , marginLeft: 15 ,alignItems:'left'}} /> },
    // { key: '2', name: 'Work Board', icon: <img src={workBoardIcon} alt="WorkBoard Icon" style={{ width: 25, height: 25, marginRight: 9 , marginLeft: 8 ,alignItems:'left'}} /> },
    { key: '3', name: 'Firecrawl', icon: <img src={fireCrawl} alt="WebCrawl Icon" style={{ width: 25, height: 25, marginRight: 9 , marginLeft: 0 ,alignItems:'left'}} /> },

  ];
  const apps = [
    {
      name: "Google Drive",
      isConnected: isConnected, 
      connect: login,
      disconnect: logout
    },
    // {
    //   name: "Work Board",
    //   isConnected: workBoardToken,
    //   connect: handleConnectWorkBoard,
    //   disconnect: handleRemoveWorkBoard,
    //   sync: syncWorkBoardActionItem
    // },
    {
      name: "Firecrawl",
      isConnected: isWebCrawlConnected,
      connect: handleWebCrawlConnect,
      disconnect: handleWebCrawlDisConnect,
    },
  ];

  const columns = [
    {
      title: 'Application',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text, record) => (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '16px' }}>
          {record.icon}
          <b>{text}</b>
        </span>
      ),
    }, 
    
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (_, record) => {
        const app = apps.find(app => app.name === record.name);
        
        return app ? (
          <Space>
            <Tag color={app.isConnected ? "green" : "red"}>
              {app.isConnected ? "Connected" : "Disconnected"}
            </Tag>
          </Space>
        ) : null; 
      },
    },
    
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'left',
      render: (_, record) => {
        const app = apps.find(app => app.name === record.name); 
        
        return app ? (
          <Space>
            {app.isConnected ? (
              <Space>
                <Tooltip title={`Disconnect from ${app.name}`}>
                  <Button onClick={app.disconnect} style={{ backgroundColor: "#6697cc" }}>Disconnect</Button>
                </Tooltip>
                {app.sync && (
                  <Tooltip title={`Sync ${app.name}`}>
                    <Button onClick={app.sync} style={{ backgroundColor: "#6697cc" }}>Sync</Button>
                  </Tooltip>
                )}
              </Space>
            ) : (
              <Tooltip title={`Connect to ${app.name}`}>
                <Button onClick={app.connect}>Connect</Button>
              </Tooltip>
            )}
          </Space>
        ) : null;
      }
    },
  ];
  const handleWebCrawlKeyInsert = async () => {
    form
      .validateFields()
      .then(async (values) => {
        const responseOfKeyStore = await storeFireCrawlKeyToDB(values?.fireCrawlKey);
        setIsWebCrawlConnected(true);
        setIsWebCrawlerModalOpen(false);
        message.success(responseOfKeyStore.data.message);
        form.resetFields();

      })
      .catch((errorInfo) => {
        console.log('Validation failed:', errorInfo);
      });

  };

  const handleInputModalClose = ()=>{
    setIsWebCrawlerModalOpen(false);
  }
  return (
    <div style={{ margin: "20px" }}>
      <div className="large-icon-container" style={{ margin: 10 }}>
        <TbPlugConnectedX className="large-icon lg" />
        <span> Connect Apps</span>
      </div>
      <Table
        loading={isLoading}
        bordered={true}
        columns={columns}
        dataSource={applicationData}
        pagination={false}
      />
      <Modal
        title={
          <>
            <ExclamationCircleOutlined style={{ color: 'red', marginRight: 8 }} />
            Google Drive File Sync Failed
          </>
        }
        open={showSyncFileAlertModal}
        onOk={() => setShowSyncFileAlertModal(false)}
        onCancel={() => setShowSyncFileAlertModal(false)}>
        <p>Please Connect With Google Drive</p>
      </Modal>

      <InputModal
        propsData={{
          title: "Add FireCrawl Key",
          data: null,
          placeholder: "Add FireCrawl Key",
          open: isWebCrawlerModalOpen,
          onCancel: handleInputModalClose,
          onOk: handleWebCrawlKeyInsert,
          okText: "Add Key",
          formItems: AddWebCrawlKeyFormData,
          form: form
        }}
      />
    </div>
  );
};

export default IntegrateApplications;