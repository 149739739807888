export const AddWebCrawlKeyFormData = [
    {
        label: "FireCrawl Key",
        name: "fireCrawlKey",
        rules: [
            {
                required: true,
                message: 'Please Input fireCrawl Key!',
            },
        ]
    }
];