import { useEffect, useState } from 'react';
import { Button, Input, List } from 'antd';
import { getUserID } from '../../Utility/service';
import { retrieveUserProfile } from '../../api/profile';
import "./ProfileInfoStyle.css";

import { editUser } from "../../api/user";
import { toast } from "react-toastify";
import TextArea from 'antd/es/input/TextArea';
import UploadPhoto from '../../component/Proflie/Upload';
import PasswordChange from '../../component/Proflie/PasswordChange';

const ProfileInfo = () => {
  const userId = getUserID();
  const [userInfo, setUserInfo] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(()=>{
      retrieveUserProfile(userId).then((user)=>{
      setUserInfo(user);
    });
  },[userId]);

  const handleUpdateClick = async (actionType) => {
    try {
      if(actionType === 'Edit') {
        setIsEditing(!isEditing);
      }
      else {
        if (userInfo) {
          if (userInfo.newPassword === userInfo.confirmPassword) {
            setUserInfo((prevState) => ({
              ...prevState,
              password: prevState.confirmPassword,
              newPassword: "",
              confirmPassword: "",
            }));
            const updatedUserResponse = await editUser(userId, userInfo);
            if (updatedUserResponse?.status === 200) {
              setIsEditing(!isEditing);
              toast.success("Profile successfully updated");
              
            }
          }
        } else {
          console.error("Failed to update user info");
        }
      }
     
    } catch (error) {
      console.error("Error updating user info", error);
    }

    window.dispatchEvent(new Event('userDataChanged'));
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    // setUserInfo(originalUserInfo);
  };

  const data = [
    { title: "First Name", description: userInfo?.fname || "" },
    { title: "Last Name", description: userInfo?.lname || "" },
    { title: "Designation", description: userInfo?.designation || "" },
    { title: "Responsibility", description: userInfo?.responsibility || "" },
    {
      title: "Company Information",
      description: userInfo?.companyInformation || "",
    },
    { title: "User Role", description: userInfo?.role || "" },
    { title: "Email Address", description: userInfo.email || "" },
  ];

  const filteredData = isEditing
    ? data
    : data.filter(
        (item) =>
          item.title !== "New Password" && item.title !== "Confirm Password"
      );

  const userFirstName = userInfo?.fname?.at(0).toUpperCase();

  return (
    <>
    <div className="profile--container">
      <div className="profile-card">
        <UploadPhoto userFirstName={userFirstName} setUserInfo={setUserInfo} userId={userId} userInfo={userInfo} />
        <PasswordChange userId={userId} />
      </div>

      <div className="form-component" style={{ flexGrow: 1 }}>
        <List
          header={<strong>Your Account Details</strong>}
          size="medium"
          bordered
          dataSource={filteredData}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={item.title}
                description={
                  isEditing ? (
                    item.title === "First Name" ? (
                      <Input
                        type="text"
                        value={userInfo.fname}
                        onChange={(e) =>
                          setUserInfo({ ...userInfo, fname: e.target.value })
                        }
                      />
                    ) : item.title === "Last Name" ? (
                      <Input
                        type="text"
                        value={userInfo.lname}
                        onChange={(e) =>
                          setUserInfo({ ...userInfo, lname: e.target.value })
                        }
                      />
                    ) : item.title === "Designation" ? (
                      <TextArea
                        value={userInfo.designation}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            designation: e.target.value,
                          })
                        }
                      />
                    ) : item.title === "Responsibility" ? (
                      <TextArea
                        value={userInfo.responsibility}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            responsibility: e.target.value,
                          })
                        }
                      />
                    ) : item.title === "Company Information" ? (
                      <TextArea
                        value={userInfo.companyInformation}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            companyInformation: e.target.value,
                          })
                        }
                      />
                    ) : item.title === "User Role" ? (
                      <Input disabled value={userInfo.role} />
                    ) : item.title === "Email Address" ? (
                      <Input disabled value={userInfo.email} />
                    ) : null
                  ) : (
                    <span>{item.description}</span>
                  )
                }
              />
            </List.Item>
          )}
        />
        <div className="text-end mt-2">
          <Button onClick={() => handleUpdateClick(!isEditing ? "Edit" : "Update")}>
            {!isEditing ? "Edit" : "Update"}
          </Button>

          {isEditing && (
            <Button danger className="ms-2" onClick={handleCancelClick}>
              Cancel
            </Button>
          )}
        </div>
      </div>
    </div>
  </>
  );
};

export default ProfileInfo;